import React, { useState } from 'react'
import { Bold, MqDesktop, MqTablet } from 'streamr-ui/css/consts'
import styled from 'styled-components'
import Display from '~shared/Display'
import { NewsletterCaptcha as PrestyledNewsletterCaptcha } from './NewsletterCaptcha'

export function NewsletterForm({ onFormSubmitted }) {
    const [email, setEmail] = useState('')

    const [isSubmitting, setIsSubmitting] = useState(false)

    const [showCaptcha, setShowCaptcha] = useState(false)

    const canSubmit = !!email && !isSubmitting && !showCaptcha

    const [form, setForm] = useState(null)

    return (
        <NewsletterFormRoot
            ref={setForm}
            onSubmit={async (e) => {
                e.preventDefault()

                if (!canSubmit) {
                    return
                }

                setShowCaptcha(true)
            }}
        >
            <Title>
                <Display as="span" mobile="none" tablet="inline">
                    Subscribe to the Streamr newsletter
                </Display>
                <Display as="span" tablet="none">
                    Subscribe to the newsletter
                </Display>
            </Title>
            <Fields>
                <EmailInput
                    readOnly={showCaptcha}
                    aria-label="email"
                    aria-required="true"
                    type="email"
                    data-inputmask=""
                    name="fields[email]"
                    placeholder="Email"
                    autoComplete="email"
                    onChange={(e) => {
                        setEmail(e.target.value)
                    }}
                    value={email}
                />
                <SubmitButton type="submit" disabled={!canSubmit}>
                    {showCaptcha || isSubmitting ? 'Submitting…' : 'Subscribe'}
                </SubmitButton>
            </Fields>
            {showCaptcha && (
                <NewsletterCaptcha
                    form={form}
                    onBeforeSubmit={() => {
                        setIsSubmitting(true)
                    }}
                    onAfterSubmit={() => {
                        setEmail('')

                        if (typeof onFormSubmitted === 'function') {
                            onFormSubmitted()
                        }
                    }}
                    onDone={() => {
                        setIsSubmitting(false)

                        setShowCaptcha(false)
                    }}
                />
            )}
            <input type="hidden" name="ml-submit" value="1" />
            <input type="hidden" name="anticsrf" value="true" />
        </NewsletterFormRoot>
    )
}

const NewsletterCaptcha = styled(PrestyledNewsletterCaptcha)`
    margin-top: 1rem;
`

const Title = styled.div`
    font-weight: ${Bold};
    letter-spacing: 0.0625rem;
    margin-bottom: 1.5rem;
    text-transform: uppercase;
`

const EmailInput = styled.input`
    appearance: none;
    border: 0.0625rem solid #efefef;
    border-radius: 0.25rem;
    box-sizing: border-box;
    color: #323232;
    outline: 0;
    padding: 0 1rem;
    transition: 200ms border-color;
    width: 100%;

    &:focus {
        border-color: #0324ff;
    }

    &::placeholder {
        color: #cdcdcd;
    }
`

const SubmitButton = styled.button`
    appearance: none;
    background-color: #efefef;
    border: 0;
    border-radius: 0.25rem;
    color: #323232;
    font-weight: 500;
    height: 3.5rem;
    outline: 0;
    padding: 0;
    transform: translateZ(0) scale(1);
    transition: 200ms;
    transition-property: background-color, transform;
    width: 100%;

    &:hover,
    &:focus {
        background-color: #e7e7e7;
        transform: translateZ(0) scale(1.05);
    }

    &:active {
        background-color: #d8d8d8;
        transform: translateZ(0) scale(0.95);
        transition-duration: 10ms;
        transition-timing-function: ease-out;
    }

    &:disabled {
        background-color: #efefef;
        color: #aaa;
        transform: translateZ(0);
    }
`

const Fields = styled.div`
    @media ${MqDesktop} {
        display: flex;
        gap: 1rem;
    }
`

const NewsletterFormRoot = styled.form`
    padding: 1rem 0 3.5rem;

    ${EmailInput},
    ${SubmitButton} {
        height: 3.5rem;
    }

    @media ${MqTablet} {
        padding: 0.5rem 0 2rem;
    }

    @media ${MqDesktop} {
        font-size: 0.875rem;
        padding: 4rem 0 5rem;

        ${SubmitButton} {
            width: 9rem;
        }

        ${EmailInput} {
            width: 19rem;
        }

        ${EmailInput},
        ${SubmitButton} {
            height: 2.5rem;
        }
    }
`
