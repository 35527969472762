import { FooterColumn, FooterColumns, Footer as LayoutFooter } from '@streamr/streamr-layout'
import React from 'react'
import { MqDesktop, MqTablet } from 'streamr-ui/css/consts'
import styled from 'styled-components'
import { openNewsletterConfirmation } from '~utils'
import urls from '~utils/urls'
import { NewsletterForm } from '../../NewsletterForm'
import Link from '../Link'
import { APPS, NETWORK, RESOURCES, TOKEN } from '../Nav'
import SocialChannels from './SocialChannels'

const UnstyledFooter = (props) => {
    return (
        <LayoutFooter {...props}>
            <FooterColumns>
                <FooterColumn title={NETWORK}>
                    <Link to={urls.network}>Overview</Link>
                    <Link to={urls.stakeAndEarn}>Staking</Link>
                    <Link href={urls.apps.networkExplorer}>Explorer</Link>
                </FooterColumn>
                <FooterColumn title={RESOURCES}>
                    <Link href={urls.token}>{TOKEN}</Link>
                    <Link href={urls.docs.index}>Docs</Link>
                    <Link newTab href={urls.community.blog}>
                        Blog
                    </Link>
                    <Link href={urls.grants}>Grants</Link>
                    <Link to={urls.project.ecosystem}>Ecosystem</Link>
                    <Link to={urls.project.roadmap}>Roadmap</Link>
                    <Link to={urls.whitepapers}>Whitepapers</Link>
                    <Link to={urls.team}>Team</Link>
                    <Link to={urls.project.design}>Design assets</Link>
                    <Link to={urls.project.tokenMigration}>Token migration</Link>
                </FooterColumn>
                <FooterColumn title={APPS}>
                    <Link href={urls.apps.hub}>Hub</Link>
                    <Link to={urls.caseStudies.dataUnions}>Data Unions</Link>
                    <Link href="https://thechat.app/">Thechat.app</Link>
                </FooterColumn>
                <FooterColumn title="Contact">
                    <Link href={urls.contact.general}>General</Link>
                    <Link href={urls.contact.media}>Media</Link>
                    <Link href={urls.contact.jobs}>Jobs</Link>
                    <Link href={urls.contact.business}>Business</Link>
                </FooterColumn>
                <FooterColumn title="Legal">
                    <Link newTab href={urls.documents.terms}>
                        Terms &amp; Conditions
                    </Link>
                    <Link newTab href={urls.documents.privacy}>
                        Privacy
                    </Link>
                </FooterColumn>
            </FooterColumns>
            <SocialChannels />
            <NewsletterForm
                onFormSubmitted={() => {
                    openNewsletterConfirmation()
                }}
            />
        </LayoutFooter>
    )
}

const Footer = styled(UnstyledFooter)`
    color: #0c009a;
    background: #fcfcfc;
    overflow: hidden;

    ${SocialChannels} {
        svg {
            path {
                fill: #0c009a;
            }
        }
    }

    @media ${MqTablet} {
        ${SocialChannels},
        ${NewsletterForm} {
            margin-left: auto;
            margin-right: auto;
            max-width: 584px;
        }
    }

    @media ${MqDesktop} {
        ${SocialChannels},
        ${NewsletterForm} {
            max-width: none;
        }
    }
`

export default Footer
