import React, { useCallback, useEffect, useRef, useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import styled from 'styled-components'

export function NewsletterCaptcha({ className, form, onBeforeSubmit, onAfterSubmit, onDone }) {
    const [abortController, setAbortController] = useState()

    useEffect(
        function abortCurrentController() {
            return () => {
                if (abortController) {
                    abortController.abort()
                }
            }
        },
        [abortController],
    )

    const onBeforeSubmitRef = useRef(onBeforeSubmit)

    if (onBeforeSubmitRef.current !== onBeforeSubmit) {
        onBeforeSubmitRef.current = onBeforeSubmit
    }

    const onAfterSubmitRef = useRef(onAfterSubmit)

    if (onAfterSubmitRef.current !== onAfterSubmit) {
        onAfterSubmitRef.current = onAfterSubmit
    }

    const onDoneRef = useRef(onDone)

    if (onDoneRef.current !== onDone) {
        onDoneRef.current = onDone
    }

    const submit = useCallback(async () => {
        if (!form) {
            return
        }

        const newAbortController = new AbortController()

        const { signal } = newAbortController

        setAbortController(newAbortController)

        if (typeof onBeforeSubmitRef.current === 'function') {
            onBeforeSubmitRef.current(true)
        }

        try {
            const response = await fetch(
                'https://assets.mailerlite.com/jsonp/1359367/forms/148043583523390785/subscribe',
                {
                    method: 'POST',
                    body: new FormData(form),
                },
            )

            if (signal.aborted) {
                return
            }

            const body = await response.json()

            if (signal.aborted) {
                return
            }

            if (!body.success) {
                throw new Error(`Failed to submit form: ${JSON.stringify(body)}`)
            }

            if (typeof onAfterSubmitRef.current === 'function') {
                onAfterSubmitRef.current()
            }
        } catch (e) {
            console.warn('Failed to submit the newsletter form', e)
        } finally {
            if (typeof onDoneRef.current === 'function') {
                onDoneRef.current()
            }
        }
    }, [form])

    return (
        <CaptchaWrap className={className}>
            <ReCAPTCHA
                sitekey="6Lf1KHQUAAAAAFNKEX1hdSWCS3mRMv4FlFaNslaD"
                onChange={async (token) => {
                    if (token) {
                        try {
                            await submit()
                        } catch (_) {
                            // Noop.
                        }
                    }
                }}
            />
        </CaptchaWrap>
    )
}

const CaptchaWrap = styled.div`
    height: 4.625rem;
`

export function useMailerLiteAnalyticsEffect() {
    useEffect(() => {
        ;(async () => {
            try {
                await fetch(
                    'https://assets.mailerlite.com/jsonp/1359367/forms/148043583523390785/takel',
                )
            } catch (_) {
                // Ignore
            }
        })()
    }, [])
}
